import { FC, useState, useEffect } from "react";

import cs from "classnames";

import { GoogleLoginBody } from "../../api/idp";
import nbLogo from "../../assets/logo_large.png";
import { isMobile, isMobileMetamask } from "../../helpers/browserDetecting";
import commonStyles from "../../styles/common.module.scss";
import { EmailForm } from "../EmailForm";
import GoogleSignInButton from "../GoogleSinInButton";
import { usePublicSettings } from "../PublicSettingsProvider";
import styles from "./styles.module.css";

type InitialScreenProps = {
  onEmailFormFinish: (email: string) => void;
  returnUrl: string;
  onError: (msg: string) => void;
  loading: boolean;
  setLoading: (v: boolean) => void;
  displayNewWindowTrigger: (loginParams: GoogleLoginBody) => void;
  isExternalTopWin: boolean;
  onAccountNotFound: (email: string) => void;
};

const InitialScreen: FC<InitialScreenProps> = ({
  onEmailFormFinish,
  returnUrl,
  onError,
  displayNewWindowTrigger,
  isExternalTopWin,
  onAccountNotFound,
}) => {
  const [publicSettings] = usePublicSettings();
  const [hideGoogleSignIn, setHideGoogleSignIn] = useState(false);

  const renderTitle = () => {
    const companyName = (
      <span className={commonStyles.textBold}>{publicSettings?.appName}</span>
    );
    if (publicSettings?.ethAddressToCreateWallet) {
      if (publicSettings?.appName) {
        return <>Sign up for {companyName}</>;
      } else {
        return "Sign up";
      }
    } else {
      if (publicSettings?.appName) {
        return <>Log in to {companyName}</>;
      } else {
        return "Log in";
      }
    }
  };

  useEffect(() => {
    if (isMobileMetamask()) {
      setHideGoogleSignIn(true);
    } else if (isMobile()) {
      function handleEthereum() {
        const { ethereum } = window;
        ethereum && setHideGoogleSignIn(true);
      }

      if (window.ethereum) {
        setHideGoogleSignIn(true);
      } else {
        window.addEventListener("ethereum#initialized", handleEthereum, {
          once: true,
        });
        setTimeout(handleEthereum, 3000);
      }
    }
  }, []);

  return (
    <div className={commonStyles.stack}>
      <p className={cs(commonStyles.h1, commonStyles.textPrimary)}>
        {renderTitle()}
      </p>
      {!!publicSettings?.logo && (
        <p className={cs(commonStyles.logo, styles.logo)}>
          <img src={publicSettings?.logo || nbLogo} alt="Nifty Bridge Logo" />
        </p>
      )}
      {!hideGoogleSignIn ? (
        <div className={styles.googleWrapper}>
          <GoogleSignInButton
            key={publicSettings?.darkMode}
            returnUrl={returnUrl}
            onError={onError}
            displayNewWindowTrigger={displayNewWindowTrigger}
            isExternalTopWin={isExternalTopWin}
            onAccountNotFound={onAccountNotFound}
          />
        </div>
      ) : (
        <p className={styles.openWithChromeText}>
          Open in chrome to login with google
        </p>
      )}
      <p className={cs(commonStyles.textGrey, styles.separatorText)}>or</p>
      <EmailForm onFinish={onEmailFormFinish} />
    </div>
  );
};

export default InitialScreen;
