import { useCallback, useEffect } from "react";

import { usePublicSettings } from "../components/PublicSettingsProvider";
import { isPublicIncomeEvent } from "../helpers/isPublicIncomeEvent";
import {
  PUBLIC_INCOME_EVENT_TYPES,
  PublicIncomeEvent,
  SetLoginSettingEventData,
} from "../types/publicIncomeEvent";

export const useHandlePublicIncomeEvents = () => {
  const [, setSettings] = usePublicSettings();

  const onLoginSettings = useCallback(
    (message: SetLoginSettingEventData["message"]) => {
      setSettings(message);
    },
    [setSettings]
  );

  const handler = useCallback(
    (event: PublicIncomeEvent) => {
      const { data } = event;
      const { message, type } = data;

      switch (type) {
        case PUBLIC_INCOME_EVENT_TYPES.SET_LOGIN_SETTINGS:
          onLoginSettings(message);
          break;
        default:
          console.error(`Event ${type} not found`);
      }
    },
    [onLoginSettings]
  );

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      if (isPublicIncomeEvent(event)) {
        return handler(event);
      }
    };

    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [handler]);
};
