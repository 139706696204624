import {
  IDENTITY_SERVER_URI,
  IDP_CLIENT,
  IDP_CLIENT_SECRET,
  loginResponsePath,
} from "../constants";

export type LoginBody = {
  email: string;
  returnUrl: string;
  otpCode: string;
};

export type LoginResponse = {
  returnUrl: string;
};

export class AccountNotFoundError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export const otpLogin = async (body: LoginBody) => {
  const response = await fetch(`${IDENTITY_SERVER_URI}/api/account/otpLogin`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      email: body.email,
      returnUrl: body.returnUrl,
      otpCode: body.otpCode,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    console.log(response);

    const msg = await response.text();
    const errMeta = JSON.parse(msg || "{}");
    if (errMeta?.Code === "UserNotFound") {
      throw new AccountNotFoundError(errMeta.message || "Account not found");
    }

    throw new Error("Response is not ok");
  }

  return (await response.json()) as LoginResponse;
};

export type GoogleLoginBody = {
  token: string;
  returnUrl: string;
};

type GoogleLoginResponse = {
  returnUrl: string;
};

export const googleLogin = async (body: GoogleLoginBody) => {
  const response = await fetch(
    `${IDENTITY_SERVER_URI}/api/account/googleLogin`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    const msg = await response.text();
    console.log(response, msg);
    const errMeta = JSON.parse(msg || "{}");
    if (errMeta?.Code === "UserNotFound") {
      throw new AccountNotFoundError(errMeta.message || "Account not found");
    }
    throw new Error("Response is not ok");
  }

  return (await response.json()) as GoogleLoginResponse;
};

type FetchTokensResponse = {
  id_token: string;
  access_token: string;
  expires_in: number;
  token_type: string;
  refresh_token: string;
  scope: string;
};

export const fetchTokens = async (
  code: string
): Promise<FetchTokensResponse> => {
  const body = {
    client_id: IDP_CLIENT,
    client_secret: IDP_CLIENT_SECRET,
    grant_type: "authorization_code",
    redirect_uri: window.location.origin.replace(/\/$/, "") + loginResponsePath,
    scope: "openid profile offline_access",
    code,
  };
  const response = await fetch(`${IDENTITY_SERVER_URI}/connect/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;",
    },
    body: new URLSearchParams(body).toString(),
  });

  if (!response.ok) {
    throw new Error("Network error");
  }

  return response.json();
};
