import {
  CSSProperties,
  ReactNode,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import * as Sentry from "@sentry/react";
import { useSize } from "ahooks";
import cs from "classnames";

import { GoogleLoginBody, type LoginBody } from "../api/idp";
import { PARENT_MESSAGE_TYPE, sendMessageToParent } from "../api/parentWindow";
import ErrorScreen from "../components/ErrorScreen/ErrorScreen";
import InitialScreen from "../components/InitialScreen/InitialScreen";
import NewWindowTrigger from "../components/NewWindowTrigger/NewWindowTrigger";
import OTPFormScreen from "../components/OTPFormScreen/OTPFormScreen";
import { usePublicSettings } from "../components/PublicSettingsProvider";
import { redirectOnSuccess } from "../helpers/redirectOnSuccess";
import { useHandlePublicIncomeEvents } from "../hooks/useHandlePublicIncomeEvents";
import { Scopes } from "../types/common";
import styles from "./styles.module.scss";

const getReturnUrl = (): URL | undefined => {
  try {
    const searchParams = new URLSearchParams(window.location.search);
    const returnUrlRaw = searchParams.get("ReturnUrl");
    if (!returnUrlRaw) throw new Error("ReturnUrl not found");
    const returnUrl = new URL(returnUrlRaw);
    return returnUrl;
  } catch (err) {
    console.log(err);
  }
};

const PageWrapper = forwardRef<HTMLDivElement, { children: ReactNode }>(
  ({ children }, ref) => {
    const [publicSettings] = usePublicSettings();

    useLayoutEffect(() => {
      if (publicSettings?.backgroundColor) {
        document.documentElement.style.backgroundColor =
          publicSettings?.backgroundColor;

        document.documentElement.setAttribute(
          "theme",
          publicSettings?.darkMode ? "dark" : "light"
        );
      }
    }, [publicSettings?.backgroundColor, publicSettings?.darkMode]);

    return (
      <div
        className={cs(styles.pageWrapper)}
        ref={ref}
        style={
          {
            "--backgorundColor": publicSettings?.backgroundColor || "#fff",
          } as CSSProperties
        }
      >
        {children}
      </div>
    );
  }
);
PageWrapper.displayName = "PageWrapper";

enum ScreenType {
  DEFAULT = "DEFAULT",
  OTP = "OTP",
  ERROR = "ERROR",
  NEW_WINDOW_TRIGGER = "NEW_WINDOW_TRIGGER",
}

type DefaultScreen = {
  type: ScreenType.DEFAULT;
};

type OtpScreen = {
  type: ScreenType.OTP;
  state: {
    email: string;
  };
};

type ErrorScreen = {
  type: ScreenType.ERROR;
  state: {
    message: string;
  };
};

type NewWindowTriggerScreen = {
  type: ScreenType.NEW_WINDOW_TRIGGER;
  state: GoogleLoginBody | LoginBody;
};

type Screen = DefaultScreen | OtpScreen | ErrorScreen | NewWindowTriggerScreen;

//TODO: use react router
//TODO: move common login to context Provider
const LoginPage = () => {
  //every screen should have state, to avoid state in component for every
  const [activeScreen, setActiveScreen] = useState<Screen>({
    type: ScreenType.DEFAULT,
  });

  const [loading, setLoading] = useState(false);

  const animRef = useRef<HTMLDivElement | null>(null);

  const returnUrl = useMemo(getReturnUrl, []);
  const redirect_uri = returnUrl?.searchParams.get("redirect_uri");
  const screensWrapperRef = useRef(null);
  const screensWrapperSize = useSize(screensWrapperRef);

  useHandlePublicIncomeEvents();

  // inform about Ready
  useEffect(() => {
    setTimeout(() => {
      sendMessageToParent({ type: PARENT_MESSAGE_TYPE.READY, message: true });
    }, 300);
  }, []);

  // inform parent about Active Screen
  useEffect(() => {
    sendMessageToParent({
      type: PARENT_MESSAGE_TYPE.ACTIVE_SCREEN_UPDATE,
      message: activeScreen.type,
    });
  }, [activeScreen]);

  // inform parent about Size
  useEffect(() => {
    if (!screensWrapperSize) return;
    sendMessageToParent({
      type: PARENT_MESSAGE_TYPE.SIZE_UPDATE,
      message: screensWrapperSize,
    });
  }, [screensWrapperSize]);

  const scopes = useMemo(
    () =>
      (returnUrl?.searchParams.get("scope")?.split(" ") || []).map((sc) =>
        sc.trim()
      ) as Scopes[],
    [returnUrl]
  );

  const isExternalTopWin = useMemo(
    () => scopes.includes(Scopes.gating),
    [scopes]
  );

  const onEmailFormFinish = (userEmail: string) => {
    setActiveScreen({ type: ScreenType.OTP, state: { email: userEmail } });
  };

  const onError = (msg: string) => {
    Sentry.captureException(new Error(msg));
    setActiveScreen({ type: ScreenType.ERROR, state: { message: msg } });
    sendMessageToParent({
      type: PARENT_MESSAGE_TYPE.ERROR,
      message: msg,
    });
  };
  // TODO: investigate why we call redirect instead of just send message
  const onAccountNotFound = (email: string) => {
    if (!redirect_uri) return;

    const redurectURL = new URL(redirect_uri);
    redurectURL.searchParams.append("error", "NotFound");
    redurectURL.searchParams.append("email", email);
    redirectOnSuccess(redurectURL.toString());
  };

  const resetScreens = () => {
    setLoading(false);
    setActiveScreen({
      type: ScreenType.DEFAULT,
    });
  };

  const displayNewWindowTrigger = (
    loginParams: GoogleLoginBody | LoginBody
  ) => {
    setActiveScreen({
      type: ScreenType.NEW_WINDOW_TRIGGER,
      state: loginParams,
    });
  };

  const renderActiveScreen = () => {
    if (!returnUrl || !redirect_uri) return null;
    switch (activeScreen.type) {
      case ScreenType.OTP:
        return (
          <OTPFormScreen
            onBack={resetScreens}
            email={activeScreen.state.email}
            onError={onError}
            returnUrl={returnUrl.toString()}
            onAccountNotFound={onAccountNotFound}
            isExternalTopWin={isExternalTopWin}
            displayNewWindowTrigger={displayNewWindowTrigger}
          />
        );
      case ScreenType.ERROR:
        return (
          <ErrorScreen
            message={activeScreen.state.message}
            onReset={resetScreens}
          />
        );
      case ScreenType.NEW_WINDOW_TRIGGER:
        return (
          <NewWindowTrigger
            loginParams={activeScreen.state}
            onBack={resetScreens}
            onError={onError}
            onAccountNotFound={onAccountNotFound}
          />
        );
      case ScreenType.DEFAULT:
      default:
        return (
          <InitialScreen
            returnUrl={returnUrl.toString()}
            onEmailFormFinish={onEmailFormFinish}
            onError={onError}
            onAccountNotFound={onAccountNotFound}
            loading={loading}
            setLoading={setLoading}
            displayNewWindowTrigger={displayNewWindowTrigger}
            isExternalTopWin={isExternalTopWin}
          />
        );
    }
  };

  if (!returnUrl || !redirect_uri) {
    return (
      <PageWrapper ref={screensWrapperRef}>ReturnUrl not found</PageWrapper>
    );
  }

  return (
    <div>
      <PageWrapper ref={screensWrapperRef}>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={activeScreen.type}
            nodeRef={animRef}
            addEndListener={(done) => {
              animRef.current?.addEventListener("transitionend", done, false);
            }}
            classNames="fade"
          >
            <div ref={animRef}>{renderActiveScreen()}</div>
          </CSSTransition>
        </SwitchTransition>
      </PageWrapper>
    </div>
  );
};

export default LoginPage;
