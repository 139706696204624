import { FC, useCallback } from "react";
import { isIOS, isSafari } from "react-device-detect";

import type { CredentialResponse } from "@react-oauth/google";

import { GoogleLoginBody } from "../api/idp";
import { redirectOnSuccess } from "../helpers/redirectOnSuccess";
import { useGoogleAccountCreationRequest } from "../hooks/useGoogleAccountCreationRequest";
import { useGoogleLogin } from "../hooks/useGoogleLogin";
import { useNewWindowLogin } from "../hooks/useNewWindowLogin";
import { EVENT_TYPE_TO_REQUEST_PAGE } from "../types/eventsToRequestPage";
import { GoogleSignInInit } from "./GoogleSignInInit";

// import { usePublicSettings } from "./PublicSettingsProvider";

type GoogleSignInProps = {
  returnUrl: string;
  onError: (msg: string) => void;
  displayNewWindowTrigger: (loginParams: GoogleLoginBody) => void;
  isExternalTopWin: boolean;
  onAccountNotFound: (email: string) => void;
};

const GoogleSignInButton: FC<GoogleSignInProps> = ({
  returnUrl,
  onError,
  displayNewWindowTrigger,
  isExternalTopWin,
  onAccountNotFound,
}) => {
  // const [publicSettings] = usePublicSettings();
  const onAccountCreationRequest = useGoogleAccountCreationRequest({ onError });

  const { openLoginWindow } = useNewWindowLogin<GoogleLoginBody>({
    onError,
    sendParamsEventType: EVENT_TYPE_TO_REQUEST_PAGE.GOOGLE_LOGIN_BODY,
    onAccountNotFound,
    onAccountCreationRequest,
  });

  const onSuccessLogin = useCallback(async (url: string) => {
    await redirectOnSuccess(url);
  }, []);

  const onLoginError = useCallback(async (msg: string) => {
    onError(msg || "Internal error, try again later.");
  }, []);

  const loginByGoogle = useGoogleLogin({
    onSuccess: onSuccessLogin,
    onAccountCreationRequest,
    onAccountNotFound,
    onError: onLoginError,
  });

  const onSignInFinish = useCallback(
    async ({ credential: idToken }: CredentialResponse) => {
      if (!idToken) throw new Error("Google idToken not found");
      try {
        const loginParams = {
          token: idToken,
          returnUrl,
        };
        // we have some problems with cookie and IDP redirect in these browsers and devices, so we have to open new top page and handle login there
        if ((isIOS || isSafari) && isExternalTopWin) {
          const win = openLoginWindow(loginParams);
          //if window haven't opeend - display button to open new window with google flow
          if (!win) {
            displayNewWindowTrigger(loginParams);
          }
        } else {
          await loginByGoogle(loginParams);
        }
      } catch (err: any) {
        console.error(err?.message);
        onError("Internal error, try again.");
      }
    },
    [
      returnUrl,
      onError,
      openLoginWindow,
      displayNewWindowTrigger,
      isExternalTopWin,
    ]
  );

  return (
    <GoogleSignInInit
      callback={onSignInFinish}
      // key={publicSettings?.allowCreation ? "1" : "0"}
    />
  );
};

export default GoogleSignInButton;
