import { GoogleLoginBody, LoginBody } from "../api/idp";
import type { PublicSettings } from "./publicSettings";

export enum EVENT_TYPE_TO_REQUEST_PAGE {
  SET_PUBLIC_SETTINGS = "SET_PUBLIC_SETTINGS",
  EMAIL_LOGIN_BODY = "EMAIL_LOGIN_BODY",
  GOOGLE_LOGIN_BODY = "GOOGLE_LOGIN_BODY",
}

export type EmailLoginBodyData = {
  type: EVENT_TYPE_TO_REQUEST_PAGE.EMAIL_LOGIN_BODY;
  message: LoginBody;
};

export type GoogleLoginBodyData = {
  type: EVENT_TYPE_TO_REQUEST_PAGE.GOOGLE_LOGIN_BODY;
  message: GoogleLoginBody;
};

export type SetPublicSettingsData = {
  type: EVENT_TYPE_TO_REQUEST_PAGE.SET_PUBLIC_SETTINGS;
  message: PublicSettings;
};

export type ToRequestPageData =
  | EmailLoginBodyData
  | GoogleLoginBodyData
  | SetPublicSettingsData;

export type EventToRequestPage = Omit<MessageEvent, "data"> & {
  data: ToRequestPageData;
};
