import { useCallback, useRef } from "react";

import { jwtDecode, type JwtPayload } from "jwt-decode";

import {
  AccountNotFoundError,
  googleLogin,
  type GoogleLoginBody,
} from "../api/idp";
import { usePublicSettings } from "../components/PublicSettingsProvider";

type UseGoogleLoginProps = {
  onSuccess: (returnUrl: string) => void;
  onAccountNotFound: (email: string) => void;
  onAccountCreationRequest: (params: { idToken: string }) => void;
  onError: (msg: string) => void;
};

export const useGoogleLogin = ({
  onSuccess,
  onAccountNotFound,
  onAccountCreationRequest,
  onError,
}: UseGoogleLoginProps) => {
  const [publicSettings] = usePublicSettings();
  //HOTFIX in loginHandler we still have old NULL value
  const publicSettingsRef = useRef(publicSettings);
  publicSettingsRef.current = publicSettings;
  const loginHandler = useCallback(
    async (data: GoogleLoginBody) => {
      try {
        const response = await googleLogin(data);
        onSuccess(response.returnUrl);
      } catch (error: any) {
        console.error(
          "ERRROR:",
          error,
          JSON.stringify(publicSettingsRef.current)
        );
        if (error instanceof AccountNotFoundError) {
          if (publicSettingsRef.current?.allowCreation) {
            onAccountCreationRequest({ idToken: data.token });
          } else {
            const jwtPaylaod = jwtDecode(data.token) as JwtPayload & {
              email?: string;
            };
            onAccountNotFound(jwtPaylaod?.email || " ");
          }
        } else {
          onError(error?.msg || "Failed");
        }
      }
    },
    [publicSettings]
  );

  return loginHandler;
};
