import { useCallback } from "react";

import { generateEmailConfirmationToken } from "../api/ncwallet";
import { PARENT_MESSAGE_TYPE, sendMessageToParent } from "../api/parentWindow";

type UseOtpAccountCreationRequestParams = {
  onError: (msg: string) => void;
};

export const useOtpAccountCreationRequest = ({
  onError,
}: UseOtpAccountCreationRequestParams) => {
  const requestAccountCreation = useCallback(
    async (props: { email: string; otpCode: string }) => {
      try {
        const params = await generateEmailConfirmationToken({
          otp: props,
        });
        sendMessageToParent({
          type: PARENT_MESSAGE_TYPE.ACCOUNT_CREATION_REQUEST,
          message: params,
        });
      } catch (err: any) {
        console.log(err?.message);
        onError("Account creation failed");
      }
    },
    []
  );

  return requestAccountCreation;
};
