import { FC, useRef, useState } from "react";
import { isIOS, isSafari } from "react-device-detect";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import OTPInput from "react-otp-input";

import cs from "classnames";

import { AccountNotFoundError, otpLogin, type LoginBody } from "../../api/idp";
import { generateOTPCode } from "../../api/ncwallet";
import { redirectOnSuccess } from "../../helpers/redirectOnSuccess";
import { useMounted } from "../../hooks/useMounted";
import { useNewWindowLogin } from "../../hooks/useNewWindowLogin";
import { useOtpAccountCreationRequest } from "../../hooks/useOtpAccountCreationRequest";
import commonStyles from "../../styles/common.module.scss";
import { EVENT_TYPE_TO_REQUEST_PAGE } from "../../types/eventsToRequestPage";
import { usePublicSettings } from "../PublicSettingsProvider";
import styles from "./styles.module.scss";

const isValidOtpCode = (otpCode: string) => otpCode.length === 6;

type OTPFormScreenProps = {
  onBack: () => void;
  onError: (msg: string) => void;
  email: string;
  returnUrl: string;
  onAccountNotFound: (email: string) => void;
  isExternalTopWin: boolean;
  displayNewWindowTrigger: (loginParams: LoginBody) => void;
};

const OTPFormScreen: FC<OTPFormScreenProps> = ({
  email,
  onBack,
  onError,
  returnUrl,
  onAccountNotFound,
  isExternalTopWin,
  displayNewWindowTrigger,
}) => {
  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState<string>("");
  const [otpCode, setOtpCode] = useState("");
  const [publicSettings] = usePublicSettings();
  const ref = useRef<HTMLInputElement | null>(null);
  const requestAccountCreation = useOtpAccountCreationRequest({ onError });

  useMounted(async () => {
    try {
      ref.current?.focus();
      setTimeout(() => {
        ref.current?.focus();
      }, 1000);
      await generateOTPCode({
        email,
        brandingId: publicSettings?.otpEmailBrandingId,
      });
      setLoading(false);
    } catch (err) {
      console.error(err);
      onError("Otp code wasn't sent, try again later or contact administrator");
      onBack();
    }
  });

  const { openLoginWindow } = useNewWindowLogin<LoginBody>({
    onError: (...params) => {
      console.log("ON Error", ...params);
    },
    sendParamsEventType: EVENT_TYPE_TO_REQUEST_PAGE.EMAIL_LOGIN_BODY,
    onAccountNotFound,
    onAccountCreationRequest: requestAccountCreation,
  });

  const handleOtpCodeChange = async (code: string) => {
    setOtpCode(code);
    if (!isValidOtpCode(code)) {
      return;
    }

    setLoading(true);
    try {
      const loginBody: LoginBody = {
        email,
        returnUrl: returnUrl.toString(),
        otpCode: code,
      };
      if ((isIOS || isSafari) && isExternalTopWin) {
        const win = openLoginWindow(loginBody);
        //if window haven't opeend - display button to open new window with google flow
        if (!win) {
          displayNewWindowTrigger(loginBody);
        }
      } else {
        const response = await otpLogin(loginBody);
        await redirectOnSuccess(response.returnUrl);
      }
    } catch (err: any) {
      console.error(err?.message, JSON.stringify({ publicSettings }));
      if (err instanceof AccountNotFoundError) {
        if (publicSettings?.allowCreation) {
          requestAccountCreation({ email, otpCode: code });
        } else {
          onAccountNotFound(email);
        }
      } else {
        setOtpCode("");
        setFormError("Incorrect OTP code");
        setLoading(false);
      }
    }
  };

  return (
    <div className={commonStyles.stack}>
      <p className={commonStyles.h1}>
        <IoChevronBackCircleOutline
          onClick={onBack}
          className={commonStyles.inlineBackBtn}
        />
        Confirm email
        <br />
        <span
          className={cs(
            commonStyles.text,
            commonStyles.textGrey,
            styles.subtitle
          )}
        >
          OTP code has been sent to
          <br />
          {email}
        </span>
      </p>
      <OTPInput
        value={otpCode}
        onChange={handleOtpCodeChange}
        numInputs={6}
        containerStyle={{ gap: "10px", justifyContent: "center" }}
        renderInput={(props, index) => (
          <input
            {...props}
            className={cs(props.className, styles.otpInput)}
            name="code"
            disabled={loading}
            inputMode="numeric"
            ref={(input) => {
              index === 0 && (ref.current = input);
              props.ref(input);
            }}
          />
        )}
      />
      {formError && <p className={commonStyles.textError}>{formError}</p>}
    </div>
  );
};
export default OTPFormScreen;
