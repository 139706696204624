import { OpenIdExtension } from "@magic-ext/oidc";
import { Magic } from "magic-sdk";

export const IDENTITY_SERVER_URI = import.meta.env.VITE_IDENTITY_SERVER_URI;
if (!IDENTITY_SERVER_URI) {
  throw new Error("VITE_IDENTITY_SERVER_URI env not found");
}
export const WALLET_API_URI = import.meta.env.VITE_WALLET_API_URI;
if (!WALLET_API_URI) {
  throw new Error("VITE_WALLET_API_URI env not found");
}
export const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;
if (!GOOGLE_CLIENT_ID) {
  throw new Error("VITE_GOOGLE_CLIENT_ID env not found");
}

export const FORGOT_PASSWORD_URL = import.meta.env.VITE_FORGOT_PASSWORD_URL;
if (!FORGOT_PASSWORD_URL) {
  throw new Error("VITE_FORGOT_PASSWORD_URL env not found");
}

export const IS_PROD = import.meta.env.VITE_IS_PROD === "true";

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const SENTRY_ENV = import.meta.env.VITE_SENTRY_ENV;

export const IDP_CLIENT = import.meta.env.VITE_IDP_CLIENT;
if (!IDP_CLIENT) {
  throw new Error("VITE_IDP_CLIENT env not found");
}
export const IDP_CLIENT_SECRET = import.meta.env.VITE_IDP_CLIENT_SECRET;
if (!IDP_CLIENT_SECRET) {
  throw new Error("VITE_IDP_CLIENT_SECRET env not found");
}

export const MAGIC_PROVIDER_ID = import.meta.env.VITE_MAGIC_PROVIDER_ID;
if (!MAGIC_PROVIDER_ID) {
  throw new Error("VITE_MAGIC_PROVIDER_ID env not found");
}
export const MAGIC_API_KEY = import.meta.env.VITE_MAGIC_API_KEY;
if (!MAGIC_API_KEY) {
  throw new Error("VITE_MAGIC_API_KEY env not found");
}

export const magicClient = new Magic(MAGIC_API_KEY, {
  extensions: [new OpenIdExtension()],
});

export const loginResponsePath = "/login-response";
