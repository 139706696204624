import { FC } from "react";

import {
  GoogleLogin,
  GoogleOAuthProvider,
  type CredentialResponse,
} from "@react-oauth/google";

import { GOOGLE_CLIENT_ID } from "../constants";
import { usePublicSettings } from "./PublicSettingsProvider";

type GoogleSignInInitProps = {
  callback: (props: CredentialResponse) => void | Promise<void>;
};

export const GoogleSignInInit: FC<GoogleSignInInitProps> = ({ callback }) => {
  const [publicSettings] = usePublicSettings();

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <GoogleLogin
        onSuccess={callback}
        onError={() => callback({})}
        type="standard"
        size="large"
        theme={publicSettings?.darkMode ? "filled_black" : "outline"}
        text="signin_with"
        shape="pill"
        logo_alignment="left"
        width={260}
      />
    </GoogleOAuthProvider>
  );
};
