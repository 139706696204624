import { useCallback, useEffect, useState } from "react";

import { PARENT_MESSAGE_TYPE, sendMessageToParent } from "../api/parentWindow";
import { usePublicSettings } from "../components/PublicSettingsProvider";
import { isEventFromRequestPage } from "../helpers/isEventFromRequestPage";
import { isEventFromResponsePage } from "../helpers/isEventFromResponsePage";
import {
  EVENT_TYPE_FROM_REQUEST_PAGE,
  EventFromRequestPage,
} from "../types/eventsFromRequestPage";
import { EVENT_TYPE_TO_REQUEST_PAGE } from "../types/eventsToRequestPage";
import {
  EVENT_TYPES_FROM_RESPONSE_PAGE,
  ResponsePageEvent,
  ResponsePageResponseData,
} from "../types/responsePage";

type UseNewWindowLoginGoogle = {
  sendParamsEventType: EVENT_TYPE_TO_REQUEST_PAGE.GOOGLE_LOGIN_BODY;
  onAccountCreationRequest: (params: { idToken: string }) => void;
};

type UseNewWindowLoginEmail = {
  sendParamsEventType: EVENT_TYPE_TO_REQUEST_PAGE.EMAIL_LOGIN_BODY;
  onAccountCreationRequest: (params: {
    email: string;
    otpCode: string;
  }) => void;
};

type UseNewWindowLogin = {
  onError: (err: string) => void;
  onLoading?: (v: boolean) => void;
  onAccountNotFound: (email: string) => void;
} & (UseNewWindowLoginEmail | UseNewWindowLoginGoogle);

// request page - page where we trying to authenticate user wihtout interaction, after sucess authentiation we should
// call redirect and visit response page
// response page - where we become after, with success/fail messages
//open new window with /login-request page, after open - send params and wait imcoming events
export const useNewWindowLogin = <LoginParams>({
  onError,
  onLoading,
  sendParamsEventType,
  onAccountCreationRequest,
  onAccountNotFound,
}: UseNewWindowLogin) => {
  const [publicSettings] = usePublicSettings();
  const [isLoading, setIsLoading] = useState(false);
  const [loginParams, setLoginParams] = useState<LoginParams | null>(null);

  const [openedWindow, setOpenedWindow] = useState<Window | null>(null);

  //when new win ready - send settings
  const onOpenedWindowReady = useCallback(() => {
    openedWindow?.postMessage(
      {
        type: EVENT_TYPE_TO_REQUEST_PAGE.SET_PUBLIC_SETTINGS,
        message: publicSettings,
      },
      window.location.origin
    );
    //TODO: create another way to send settings to request page
    setTimeout(() => {
      openedWindow?.postMessage(
        {
          type: sendParamsEventType,
          message: loginParams,
        },
        window.location.origin
      );
    }, 300);
  }, [loginParams, openedWindow, sendParamsEventType, publicSettings]);

  const onLoginError = useCallback(
    (err: string) => {
      setIsLoading(false);
      onError(err);
      openedWindow?.close();
    },
    [onError, setIsLoading, openedWindow]
  );

  const onAccountNotFoundHandler = useCallback(
    (email: string) => {
      openedWindow?.close();
      onAccountNotFound(email);
    },
    [onAccountNotFound, openedWindow]
  );

  // external response page send event about success sign-in, we should close eternal page and open the same here(in iframe) to continue
  const onResponsePageResponseEvent = useCallback(
    async (urlToRedirect: ResponsePageResponseData["message"]) => {
      openedWindow?.close();
      sendMessageToParent({
        type: PARENT_MESSAGE_TYPE.BEFORE_SUCCESS_REDIRECT,
      });
      await new Promise((r) => setTimeout(r, 100));
      window.location.href = urlToRedirect;
    },
    [openedWindow]
  );

  const handleEventFromRequestPage = useCallback(
    ({ data }: EventFromRequestPage) => {
      switch (data.type) {
        case EVENT_TYPE_FROM_REQUEST_PAGE.READY:
          onOpenedWindowReady();
          break;

        case EVENT_TYPE_FROM_REQUEST_PAGE.ACCOUNT_CREATION_REQUEST:
          if (
            sendParamsEventType ===
              EVENT_TYPE_TO_REQUEST_PAGE.EMAIL_LOGIN_BODY &&
            "email" in data.message
          ) {
            onAccountCreationRequest(data.message);
            openedWindow?.close();
          } else if (
            sendParamsEventType ===
              EVENT_TYPE_TO_REQUEST_PAGE.GOOGLE_LOGIN_BODY &&
            "idToken" in data.message
          ) {
            onAccountCreationRequest(data.message);
            openedWindow?.close();
          }
          break;
        case EVENT_TYPE_FROM_REQUEST_PAGE.ACCOUNT_NOT_FOUND:
          onAccountNotFoundHandler(data.message.email);
          break;
        case EVENT_TYPE_FROM_REQUEST_PAGE.LOGIN_ERROR:
          onLoginError(data.message);
          break;
      }
    },
    [onOpenedWindowReady, onLoginError, onAccountCreationRequest]
  );

  const handleEventFromResponsePage = useCallback(
    (event: ResponsePageEvent) => {
      const { message, type } = event.data;

      switch (type) {
        case EVENT_TYPES_FROM_RESPONSE_PAGE.AUTH_RESPONSE:
          onResponsePageResponseEvent(message);
          break;
      }
    },
    [onResponsePageResponseEvent]
  );

  useEffect(() => {
    onLoading?.(isLoading);
  }, [onLoading, isLoading]);

  //on message - from new win request page or response page
  useEffect(() => {
    if (!loginParams) return;

    const onMessage = async (event: MessageEvent) => {
      if (isEventFromResponsePage(event)) {
        handleEventFromResponsePage(event);
      } else if (isEventFromRequestPage(event)) {
        handleEventFromRequestPage(event);
      } else {
        console.log("Event not handled", event);
      }
    };
    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [
    loginParams,
    onOpenedWindowReady,
    handleEventFromResponsePage,
    handleEventFromRequestPage,
  ]);

  // eslint-disable-next-line
  const openLoginWindow = (params: LoginParams) => {
    setLoginParams(params);
    setIsLoading(true);

    // TODO: use navigate
    const win = window.open(
      `${window.location.origin}/login-request`,
      "_blank",
      // @ts-expect-error unexpected variable in window
      null
    );
    if (!win) {
      onLoginError("Failed to open new window, check website settings");
      setIsLoading(false);
      return;
    }
    win.addEventListener("beforeunload", () => {
      setIsLoading(false);
    });

    setOpenedWindow(win);
    return win;
  };

  return {
    openLoginWindow,
    isLoading,
  };
};
