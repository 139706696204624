import { FC } from "react";
import { IoChevronBackCircleOutline } from "react-icons/io5";

import { GoogleLoginBody, type LoginBody } from "../../api/idp";
import { useGoogleAccountCreationRequest } from "../../hooks/useGoogleAccountCreationRequest";
import { useNewWindowLogin } from "../../hooks/useNewWindowLogin";
import { useOtpAccountCreationRequest } from "../../hooks/useOtpAccountCreationRequest";
import commonStyles from "../../styles/common.module.scss";
import { EVENT_TYPE_TO_REQUEST_PAGE } from "../../types/eventsToRequestPage";
import Button from "../ui/Button/Button";

type NewWindowTriggerProps = {
  onBack: () => void;
  loginParams: GoogleLoginBody | LoginBody;
  onError: (msg: string) => void;
  onAccountNotFound: (email: string) => void;
};

type GoogleNewWindowTriggerParams = {
  loginParams: GoogleLoginBody;
  onError: (msg: string) => void;
  onAccountNotFound: (email: string) => void;
};

const GoogleNewWindowTrigger: FC<GoogleNewWindowTriggerParams> = ({
  loginParams,
  onError,
  onAccountNotFound,
}) => {
  const onAccountCreationRequest = useGoogleAccountCreationRequest({ onError });
  const { openLoginWindow, isLoading } = useNewWindowLogin<GoogleLoginBody>({
    onError,
    sendParamsEventType: EVENT_TYPE_TO_REQUEST_PAGE.GOOGLE_LOGIN_BODY,
    onAccountCreationRequest,
    onAccountNotFound,
  });

  const onBtnClick = () => {
    openLoginWindow(loginParams);
  };
  return (
    <Button onClick={onBtnClick} disabled={isLoading}>
      Log in
    </Button>
  );
};

type OtpNewWindowTriggerParams = {
  loginParams: LoginBody;
  onError: (msg: string) => void;
  onAccountNotFound: (email: string) => void;
};

const OtpNewWindowTrigger: FC<OtpNewWindowTriggerParams> = ({
  onError,
  onAccountNotFound,
  loginParams,
}) => {
  const onAccountCreationRequest = useOtpAccountCreationRequest({ onError });
  const { openLoginWindow, isLoading } = useNewWindowLogin<LoginBody>({
    onError,
    sendParamsEventType: EVENT_TYPE_TO_REQUEST_PAGE.EMAIL_LOGIN_BODY,
    onAccountCreationRequest,
    onAccountNotFound,
  });

  const onBtnClick = () => {
    openLoginWindow(loginParams);
  };
  return (
    <Button onClick={onBtnClick} disabled={isLoading}>
      Log in
    </Button>
  );
};

const isOtpLoginParams = (
  loginParams: LoginBody | GoogleLoginBody
): loginParams is LoginBody => "email" in loginParams;

const NewWindowTrigger: FC<NewWindowTriggerProps> = ({
  onBack,
  onError,
  loginParams,
  onAccountNotFound,
}) => {
  return (
    <div className={commonStyles.stack}>
      <div>
        <p className={commonStyles.h1}>
          <IoChevronBackCircleOutline
            onClick={onBack}
            className={commonStyles.inlineBackBtn}
          />
          Continue with {isOtpLoginParams(loginParams) ? "OTP" : "Google"}
        </p>
      </div>

      {isOtpLoginParams(loginParams) ? (
        <OtpNewWindowTrigger
          loginParams={loginParams}
          onError={onError}
          onAccountNotFound={onAccountNotFound}
        />
      ) : (
        <GoogleNewWindowTrigger
          loginParams={loginParams}
          onError={onError}
          onAccountNotFound={onAccountNotFound}
        />
      )}
    </div>
  );
};
export default NewWindowTrigger;
