import { WALLET_API_URI } from "../constants";
import { Profile, ProfileWallet, ProfileWalletType } from "../types/profile";

export type GenerateOTPCodeBody = {
  email: string;
  brandingId?: string;
};

export const generateOTPCode = async (body: GenerateOTPCodeBody) => {
  const response = await fetch(`${WALLET_API_URI}/account/otpCode`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    console.log(response);
    throw new Error("Response is not ok");
  }
};

export const getProfile = async (accessToken: string): Promise<Profile> => {
  const response = await fetch(`${WALLET_API_URI}/profile`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error("Network error");
  }

  return response.json();
};

export const verifyManifest = async (
  accessToken: string,
  walletAddress: string,
  signature: string
): Promise<ProfileWallet> => {
  const response = await fetch(
    `${WALLET_API_URI}/profile/walletRequestVerification`,
    {
      method: "POST",
      body: JSON.stringify({
        walletAddress,
        signature,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Network error");
  }

  return response.json();
};

type VerificationManifest = {
  walletAddress: string;
  nonce: string;
};

export const requestManifestToSign = async (
  accessToken: string,
  walletAddress: string,
  recreateSignature?: boolean
): Promise<VerificationManifest> => {
  const response = await fetch(
    `${WALLET_API_URI}/profile/walletRequestVerificationManifest`,
    {
      method: "POST",
      body: JSON.stringify({
        walletAddress,
        type: ProfileWalletType.Web3Auth,
        recreateSignature,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Network error");
  }

  return response.json();
};

type AttachExternalWalletProps = {
  walletAddress: string;
  signature: string;
  gatingToken: string;
  accessToken: string;
};

export const attachExternalWallet = async ({
  accessToken,
  ...body
}: AttachExternalWalletProps) => {
  const response = await fetch(`${WALLET_API_URI}/profile/attachWallet`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error("Network error");
  }

  return response.json();
};

type GenerateEmailConfirmationTokenBody =
  | {
      otp: {
        email: string;
        otpCode: string;
      };
    }
  | {
      gmail: {
        token: string;
      };
    };

type GenerateEmailConfirmationTokenResponse = {
  token: string;
  email: string;
};

export const generateEmailConfirmationToken = async (
  body: GenerateEmailConfirmationTokenBody
) => {
  const response = await fetch(
    `${WALLET_API_URI}/account/emailConfirmationToken`,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Network error");
  }

  const data =
    (await response.json()) as GenerateEmailConfirmationTokenResponse;
  return data;
};
